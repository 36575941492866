/** 시험 관리 -> 시험 정보 */

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import Modal, { IModalContentArgs } from "../components/Common/Modal";
import Spacer from "../components/Common/Spacer";
import { db, storage } from "../firebase";
import {
  currentFocusedJimunIdState,
  currentFocusedProblemIdState,
  problemCorrectAnswerState,
  problemInfosState,
  problemResponseTypeState,
  problemScoreState,
  problemSymbolTypeState,
  shortAnswerState,
  testFoldersState,
} from "../recoil";
import { quillValue } from "../stores/problem";

export default function TestInfo() {
  const [testFolders, setTestFolders] = useRecoilState(testFoldersState);
  const [isOpen, setIsOpen] = useState(false);
  const [currentDocId, setCurrentDocId] = useState("");
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  const setCurrentFocusedProblemDocId = useSetRecoilState(
    currentFocusedProblemIdState
  );
  const setCurrentFocusedDocId = useSetRecoilState(currentFocusedJimunIdState);

  function 문제_편집_모달_토글() {
    setIsRegisterModalOpen((prev) => !prev);
  }

  /** NOTE: 시험 폴더 가져오기 */
  const 데이터_가져오기 = async () => {
    const tempArray: any[] = [];
    const testFolderCollection = collection(db, "testFolder");

    const testFolderDocs = await getDocs(testFolderCollection);

    testFolderDocs.forEach((testFolderDoc) => {
      tempArray.push({ id: testFolderDoc.id, ...testFolderDoc.data() });
    });

    setTestFolders(tempArray);
  };

  useEffect(() => {
    데이터_가져오기();
  }, []);

  return (
    <div className="max-w-8xl flex flex-col px-32">
      <Spacer height={30} />

      <h1 style={{ fontSize: 26, fontWeight: 700 }}>시험 정보</h1>

      <Spacer height={30} />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 79,
          borderWidth: 1,
          borderColor: "#e0e0e0",
          backgroundColor: "#f7f7f7",
        }}
      >
        <select style={{ width: 120, height: 35 }}>
          <option value={""}>시험 폴더</option>

          {testFolders?.map((testFolder) => {
            return (
              <option value={testFolder.id}>{testFolder.testFolderName}</option>
            );
          })}
        </select>
      </div>

      <Spacer height={30} />

      <p>Tip: 학생을 연결하면 연결된 학생은 바로 응시가 가능합니다.</p>
      <p>학생 연결 전에 '시험지 보기'를 통해 문제 순서를 꼭 확인 하세요.</p>

      <button
        style={{ background: "green" }}
        onClick={() => {
          setIsRegisterModalOpen(true);
        }}
      >
        신규 등록
      </button>

      <Spacer height={30} />

      <table>
        <thead>
          <th scope="col">No.</th>
          <th scope="col">시험 폴더</th>
          <th scope="col">시험 제목</th>
          <th scope="col">문제 수</th>
          <th scope="col">학생 수</th>
          <th scope="col">실행</th>
        </thead>

        <tbody>
          {testFolders?.map((testFolder: any) => {
            return (
              <tr>
                <td>-</td>

                <td>
                  <p style={{ textAlign: "center" }}>
                    {testFolder.testFolderName}
                  </p>
                </td>

                <td>{"-"}</td>

                <td>문제 수</td>

                <td>학생 수</td>
                <td>
                  <div className="flex items-center justify-center gap-4">
                    <button>조회</button>

                    <button>삭제</button>

                    <button>문제 연결</button>

                    <button>시험지 인쇄</button>

                    <button>학생 연결</button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <Modal
        isOpen={isRegisterModalOpen}
        toggleOpen={문제_편집_모달_토글}
        fullscreen
      >
        {(props) => <ModalComponent {...props} currentDocId={currentDocId} />}
      </Modal>
    </div>
  );
}

/** 시험 정보 - 등록 Modal */
function ModalComponent({
  toggleOpen,
  currentDocId,
}: IModalContentArgs & { currentDocId: string }) {
  const [problemSet, setProblemSet] = useState<
    {
      id: string;
      problemSetName: string;
    }[]
  >([]);

  const [quillContent, setQuillContent] = useRecoilState(quillValue);
  const [curentFocusedProblemDocId, setCurrentFocusedProblemDocId] =
    useRecoilState(currentFocusedProblemIdState);
  const [currentFocusedDocId, setCurrentFocusedDocId] = useRecoilState(
    currentFocusedJimunIdState
  );

  const testFolders = useRecoilValue(testFoldersState);

  // TODO: testFolder의 doc의 id를 저장.
  const [selectedTestFolder, setSelectedTestFolder] = useState(""); // 시험 폴더
  // TODO: problemSet의 doc의 id를 저장.
  const [selectedProblemSet, setSelectedProblemSet] = useState(""); // 문제 세트
  const [selectedTitle, setSelectedTitle] = useState(""); // 제목
  const [isOnTimer, setIsOnTimer] = useState(0); // 타이머
  const [timerTime, setTimerTime] = useState(60 * 60 * 1000); // MS 단위
  const [postMethod, setPostMethod] = useState(0); // 제출 방법: [0: 자동, 1: 수동]
  const [notice, setNotice] = useState(""); // 전달 사항
  const [referenceURLs, setReferenceURLs] = useState([]); // 참고 URL

  // NOTE: 문제 세트(problemSet) 가져 오기
  const 문제_세트_가져오기 = async () => {
    const tempArray: any[] = [];
    const problemSetCollectionRef = collection(db, "problemSet");

    const problemSetDocs = await getDocs(problemSetCollectionRef);

    problemSetDocs.forEach((problemSetDoc) => {
      tempArray.push({ ...problemSetDoc.data(), id: problemSetDoc.id });
    });

    console.log(tempArray);

    setProblemSet(tempArray);
  };

  const 시험_정보_등록 = async () => {
    const testInfoCollectionRef = collection(db, "testInfo");

    try {
      await addDoc(testInfoCollectionRef, {
        testFolder: selectedTestFolder,
        problemSet: selectedProblemSet,
        title: selectedTitle,
        isOnTimer,
        timerTime,
        postMethod,
        notice,
        referenceURLs,
      });
    } catch (err) {
      alert("시험 정보를 등록 하는데 이슈가 오류가 있습니다.");

      console.error(err);
    }
  };

  useEffect(() => {
    const inner = async () => {
      문제_세트_가져오기();
    };

    inner();
  }, [currentFocusedDocId]);

  return (
    <div className="flex">
      <div className="flex h-full w-full flex-col gap-3 bg-white p-10">
        <h1 className="text-xl font-bold">시험 정보 등록</h1>

        <div className="h-1 w-full bg-black pl-5 pr-5"></div>

        <div className="flex flex-col pl-20 pr-20">
          <div className="flex">
            <label>시험 폴더</label>

            <select
              onChange={(e) => {
                setSelectedTestFolder(e.target.value);
              }}
            >
              {testFolders?.map((testFolder) => {
                return (
                  <option value={testFolder.id}>
                    {testFolder.testFolderName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="h-0.5 w-full bg-slate-200 pl-5 pr-5"></div>

        {/* 문제 세트 */}
        <div className="flex flex-col pl-20 pr-20">
          <div className="flex">
            <label>문제 세트</label>

            <select
              onChange={(e) => {
                setSelectedProblemSet(e.target.value);
              }}
            >
              {problemSet?.map((problemElement) => {
                return (
                  <option value={problemElement.id}>
                    {problemElement.problemSetName}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="h-0.5 w-full bg-slate-200 pl-5 pr-5"></div>

        {/* 제목 */}
        <div className="flex flex-col pl-20 pr-20">
          <div className="flex">
            <label>제목</label>

            <input
              type="text"
              placeholder="시험 정보 제목"
              onChange={(e) => {
                setSelectedTitle(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="h-0.5 w-full bg-slate-200 pl-5 pr-5"></div>

        <div className="flex flex-col pl-20 pr-20">
          <div className="flex">
            <label>타이머</label>

            <input
              type="radio"
              name="timer"
              value={1}
              onChange={(e) => {
                setIsOnTimer(Number(e.target.value));
              }}
            />
            <label>ON</label>

            <input
              type="radio"
              name="timer"
              value={0}
              onChange={(e) => {
                setIsOnTimer(Number(e.target.value));
              }}
            />
            <label>OFF</label>
            {isOnTimer === 1 ? (
              <>
                <input type="text" placeholder="0분"></input>
                <label>분</label>
              </>
            ) : null}
          </div>
        </div>

        <div className="h-0.5 w-full bg-slate-200 pl-5 pr-5"></div>

        <div className="flex flex-col pl-20 pr-20">
          <div className="flex">
            <label>제출 방법</label>
            <input
              type="radio"
              name="timer"
              value={0}
              onChange={(e) => {
                setPostMethod(Number(e.target.value));
              }}
            />
            <label>자동 (타이머 종료시 강제 제출)</label>

            <input
              type="radio"
              name="timer"
              value={1}
              onChange={(e) => {
                setPostMethod(Number(e.target.value));
              }}
            />
            <label>수동</label>
          </div>
        </div>

        <div className="h-0.5 w-full bg-slate-200 pl-5 pr-5"></div>

        <div className="w-full"></div>
        <button className="bg-slate-500">저장</button>
      </div>
    </div>
  );
}

/** 문제 추가 오른쪽 컴포넌트 */
function ProblemInfoComponent({ isJimun }: { isJimun: boolean }) {
  const [image, setImage] = useState(""); // 해설 이미지 주소
  const [responseType, setResponseType] = useRecoilState(
    problemResponseTypeState
  ); // 응답 유형 ("단일 선택", "복수 선택", "단답형")
  const [symbolType, setSymbolType] = useRecoilState(problemSymbolTypeState); // 기호 유형 (100 ~ 700)
  const [correctAnswer, setCorrectAnswer] = useRecoilState(
    problemCorrectAnswerState
  ); // 정답 ("A", "B", "C", "D")
  const [score, setScore] = useRecoilState(problemScoreState); // 배점
  const [hashTag, setHashTag] = useState("");
  const [problemType, setProblemType] = useState(0);
  const [shortAnswer, setShortAnswer] = useRecoilState(shortAnswerState); // 단답형 정답

  const quillContent = useRecoilValue(quillValue);

  const connectedJimunId = useRecoilValue(currentFocusedJimunIdState);
  const [currentFocusedProblemDocId, setCurrentFocusedProblemDocId] =
    useRecoilState(currentFocusedProblemIdState);
  const [currentFocusedProblemDoc, setCurrentFocusedProblemDoc] = useState<any>(
    {}
  );
  const [currentFocusedDocId, setCurrentFocusedDocId] = useRecoilState(
    currentFocusedJimunIdState
  );
  const [problemInfos, setProblemInfos] = useRecoilState(problemInfosState);

  const 해설_이미지_업로드 = async (e) => {
    // TODO: 해설_이미지가 업로드 완료 되었을 때, 미리 보기 보여주기

    try {
      const storageRef = ref(storage, `image/${Date.now()}`);

      await uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((url) => {
            alert("해설 이미지를 성공적으로 업로드 했습니다");

            setImage(url);
          })
          .catch((err) => {
            alert("해설 이미지 업로드에 실패 했습니다");
          });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const 문제_저장 = async () => {
    const problemInfoCF = collection(db, "problemInfo");
    const jimunsDocRef = doc(db, "jimuns", connectedJimunId);

    if (responseType === "단답형" && !shortAnswer) {
      return alert("단답형 인데, 단답형 정답이 없습니다.");
    } else if (responseType !== "단답형" && shortAnswer) {
      return alert("단답형 아닌데, 단답형 정답이 있습니다.");
    }

    if (!currentFocusedProblemDocId) {
      // NOTE: 문제 최초 저장
      try {
        await addDoc(problemInfoCF, {
          quillContent,
          responseType, // 응답 유형<string>
          symbolType: Number(symbolType), // 기호 유형<number>
          correctAnswer, // 정답
          score: Number(score), // 배점<number>
          problemType: 0, // TODO: Do not hard-coded here
          connectedJimunId,
          shortAnswer: shortAnswer ? shortAnswer : "",
          createdAt: new Date(),
          updatedAt: new Date(),
        });

        const jimunsCF = await getDoc(jimunsDocRef);

        await updateDoc(jimunsDocRef, {
          length: jimunsCF.data()?.length ? jimunsCF.data()?.length + 1 : 1,
        });

        alert("성공적으로 문제를 저장 했습니다.");
      } catch (err) {
        alert("문제 저장에 문제가 있습니다.");

        console.log(err);
      }
    } else {
      // NOTE: 문제 수정
      const problemInfoDocRef = doc(
        db,
        "problemInfo",
        currentFocusedProblemDocId
      );
      const problemInfoDoc = getDoc(problemInfoDocRef);

      try {
        await updateDoc(problemInfoDocRef, {
          quillContent,
          responseType, // 응답 유형<string>
          symbolType: Number(symbolType), // 기호 유형<number>
          correctAnswer, // 정답
          score: Number(score), // 배점<number>
          problemType: 0, // TODO: Do not hard-coded here
          connectedJimunId,
          shortAnswer: shortAnswer ? shortAnswer : "",
          createdAt: (await problemInfoDoc).data()?.createdAt ?? new Date(),
          updatedAt: new Date(),
        });

        alert("성공적으로 문제를 수정 했습니다.");
      } catch (err) {
        alert("문제 수정에 문제가 있습니다.");

        console.log(err);
      }
    }

    const tempArray: any[] = [];

    if (!currentFocusedDocId) {
      setProblemInfos(tempArray);

      return;
    }

    if (currentFocusedDocId) {
      const problemInfoCF = collection(db, "problemInfo");
      const q = query(
        problemInfoCF,
        where("connectedJimunId", "==", currentFocusedDocId)
      );

      const problemInfoDocs = await getDocs(q);

      problemInfoDocs.forEach((probemInfoDoc) => {
        tempArray.push({ id: probemInfoDoc.id, ...probemInfoDoc.data() });
      });

      tempArray.sort((a, b) => {
        const orderA = a?.order ?? 0;
        // @ts-ignore
        const orderB = b?.order ?? 0;

        // @ts-ignore
        return orderA - orderB;
      });

      setProblemInfos(tempArray);
    }
  };

  useEffect(() => {
    const inner = async () => {
      if (currentFocusedProblemDocId) {
        const problemInfoDocRef = doc(
          db,
          "problemInfo",
          currentFocusedProblemDocId
        );
        const problemInfoDoc = await getDoc(problemInfoDocRef);

        setCurrentFocusedProblemDoc(problemInfoDoc.data());
      }
    };

    inner();
  }, [currentFocusedProblemDocId]);

  useEffect(() => {
    if (currentFocusedProblemDoc.responseType) {
      setResponseType(currentFocusedProblemDoc.responseType);
    }

    if (currentFocusedProblemDoc.symbolType) {
      setSymbolType(currentFocusedProblemDoc.symbolType + "");
    }

    if (currentFocusedProblemDoc.correctAnswer) {
      setCorrectAnswer(currentFocusedProblemDoc.correctAnswer);
    }

    if (currentFocusedProblemDoc.score) {
      setScore(currentFocusedProblemDoc.score);
    }

    if (currentFocusedProblemDoc?.shortAnswer) {
      setShortAnswer(currentFocusedProblemDoc.shortAnswer);
    } else {
      setShortAnswer("");
    }
  }, [currentFocusedProblemDoc]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-3 bg-white p-5">
      <div className="flex">
        <div className="flex flex-col">
          <p>해설 이미지</p>
          <p>응답 유형</p>
          <p>기호 유형</p>
          <p>정답</p>
          <p>배점</p>
          <p>문제 유형</p>
          <p>단답형 정답</p>
        </div>

        <div className="flex flex-col">
          <input type={"file"} onChange={해설_이미지_업로드} />

          {/* 기호 유형 */}
          <select
            onChange={(e) => {
              setSymbolType(e.target.value);
            }}
            value={symbolType}
            defaultValue={symbolType}
          >
            <option value="100">A, B, C, D</option>
            <option value="200">A, B, C, D, E</option>
            <option value="300">F, G, H, J</option>
            <option value="400">F, G, H, J, K</option>
            <option value="500">1, 2, 3, 4</option>
            <option value="600">1, 2, 3, 4, 5</option>
            <option value="700">T, F, T, F, C, E</option>
          </select>

          {/* 정답 */}
          <div className="flex">
            {["A", "B", "C", "D"].map((value, i) => {
              return (
                <>
                  <input
                    type={"radio"}
                    id={"correctAnswer" + i}
                    name={"correctAnswer" + i}
                    value={value}
                    checked={correctAnswer === value}
                    onChange={(e) => {
                      setCorrectAnswer(e.target.value);
                    }}
                  />

                  <p>{value}</p>

                  <Spacer width={10} />
                </>
              );
            })}
          </div>

          {/* 배점 */}
          <select
            onChange={(e) => {
              setScore(Number(e.target.value));

              console.log("배점: " + e.target.value);
            }}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>

          {/* 문제 유형 */}
          <select onChange={() => {}}>
            <option value="1">(문제 유형 없음)</option>
          </select>

          {/* 단답형 정답 */}
          <input
            type={"text"}
            value={shortAnswer}
            onChange={(e) => {
              setShortAnswer(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
