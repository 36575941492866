import { atom } from "recoil";

interface TestFolder {
  testFolderName: string;
  id: string;
}

export const testFoldersState = atom({
  default: [] as TestFolder[],
  key: "testFoldersState",
});
